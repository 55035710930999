/**
* @description Help.vue
*/
<template>
  <PageWithHeader>
    <template #title>
      <t path="">{{ formatData.title }}</t>
    </template>
    <div class="fill">
      <div class="head-bg df-center">
        <p>{{ formatData.title }}</p>
      </div>
      <Button @click="translate" :progress="progress" class="round f-sm mt-16 ml-32" :class="{primary: isOrigin}" sm style="height: 22px;line-height: 18px">
        <t :path="isOrigin ? 'post_10' : 'post_11'"></t>
      </Button>
      <div class="list-wraper px-32 pb-32">
        <div class="list-item" v-for="(item, index) in formatData.content" :key="index">
          <p v-if="item.type === 1" class="mt-24 f-bold">{{ item.val }}</p>
          <p v-if="item.type === 2" class="mt-16 c-title" style="font-size: 16px;line-height: 24px;" v-html="item.val"></p>
          <div v-if="item.type === 3" class="mt-16">
            <Pic :src="item.icon" style="width: 100%" />
            <p v-if="item.val" class="df-center mt-8 c-title f-xs">{{ item.val }}</p>
          </div>
        </div>
        <!-- <div v-html="formatText"></div> -->
      </div>
    </div>
  </PageWithHeader>
</template>

<script lang="ts">
import { computed, shallowRef } from 'vue'
import { useRoute } from 'vue-router'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Pic from '@/components/Pic.vue'
import Button from '@/components/Button.vue'
import { useGoogleTranslate } from '@/pages/help/lesson'
import page from '@/pages/help/lesson.json' // type 1: 标题 2: 段落 3: 图片

export default {
  name: 'Help',
  components: {
    PageWithHeader,
    Button,
    Pic,
  },
  setup () {
    const route = useRoute()
    const query = shallowRef(route.query)
    const index = Number(query.value.index)
    const pageData = shallowRef()
    pageData.value = page[index]

    const { translate, content, progress, isOrigin } = useGoogleTranslate(pageData.value)
    const formatData = computed(() => content.value)

    return {
      query,
      progress,
      translate,
      isOrigin,
      formatData,
    }
  },
}
</script>

<style scoped lang="scss">
.head-bg{
  background-image: url(/img/help/newlesson/main_bg@2x.png);
  height: 48vw;
  width: 100vw;
  background-size: 100% 100%;
  padding-top: 5vw;
  p{
    font-size: 21px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 27px;
    text-align: center;
    width: 270px;
  }
}
</style>
