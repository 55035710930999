
import { computed, shallowRef } from 'vue'
import { useRoute } from 'vue-router'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Pic from '@/components/Pic.vue'
import Button from '@/components/Button.vue'
import { useGoogleTranslate } from '@/pages/help/lesson'
import page from '@/pages/help/lesson.json' // type 1: 标题 2: 段落 3: 图片

export default {
  name: 'Help',
  components: {
    PageWithHeader,
    Button,
    Pic,
  },
  setup () {
    const route = useRoute()
    const query = shallowRef(route.query)
    const index = Number(query.value.index)
    const pageData = shallowRef()
    pageData.value = page[index]

    const { translate, content, progress, isOrigin } = useGoogleTranslate(pageData.value)
    const formatData = computed(() => content.value)

    return {
      query,
      progress,
      translate,
      isOrigin,
      formatData,
    }
  },
}
